import React from 'react';

const Contactus = () => {
  return (
    <section className="bg-[#F37920] text-white py-10 sm:py-5 md:py-5 lg:py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold mb-2 sm:mb-4">
          Who We Are
        </h2>
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold">
          Contact Us
        </h1>
      </div>
    </section>
  );
};

export default Contactus;
